module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demo Saddleback Lawn","short_name":"Demo Saddleback Lawn","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"35d02e35b6788ffa57f9a3c6c003211f"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://saddleback-lawn.lobstermarketing.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/photo-gallery","crumbLabel":"Photo Gallery"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../../gatsby-theme-lawn-saddleback/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"300fa9fc-4605-0019-e443-ca5e56a08a7b","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjBhOTViOGVjOWNlZDRjMWJiMGUzMWJhZmYxZjgyZTk0IiwNCiAgImlhdCI6ICIxNjM1MzQyMTU3IiwNCiAgImV4cCI6ICIxOTgwOTQyMTU3IiwNCiAgInByb2plY3RfaWQiOiAiMzAwZmE5ZmM0NjA1MDAxOWU0NDNjYTVlNTZhMDhhN2IiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.QuUP4c8LvNZPkUFTE0A5tWCetS1pYiMWX24WICpIvMM","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","wWSActive":"0","siteUrl":"https://saddleback-lawn.lobstermarketing.com","title":"Demo Saddleback Lawn","description":"FieldRoutes","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","compassID":"8178cfb10750a2a33c491689ef9ff80ab3043c2dea7a983121c513d31d0a3aa9","recaptchaSiteKey":"6Ldml9ohAAAAAN-UMnJQA8gAqK0c5Md-spdN8GyL","recaptchaSecretKey":"6Ldml9ohAAAAAACq-GOoRcttDSXQsI-PRTt1z3a0","photoGalleryPathName":"photo-gallery","photoGalleryBreadcrumb":"Photo Gallery","photoGalleryActive":"0"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
